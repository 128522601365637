.personal_detail {
	padding-left: 23px;
	padding-right: 23px;
	position: relative;
}

.personal_detail .MuiGrid-spacing-xs-1 {
	margin: 0;
}
@media (min-width: 1920px) {
	.personal_detail {
		padding-left: 29px;
		padding-right: 29px;
	}
}

.personal_detail_container {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 10px;
}
.title {
	color: var(--colour-orange);
	text-transform: uppercase;
	font-weight: bold;
	font-size: 15px;
	font-family: "Avenir Next Bold";
	padding-bottom: 13px;
	height: 30px;
	display: flex;
	align-items: center;
}
.title span {
	margin-left: 5px;
}
@media (min-width: 1920px) {
	.title {
		font-size: 20px;
		padding-bottom: 17px;
	}
}

.text {
	color: var(--colour-blue);
	font-size: 15px;
	font-weight: 500;
	height: 30px;
	font-family: "Avenir Next Medium";
	display: flex;
	align-items: center;
}

.address {
	white-space: pre;
}
.title span {
	margin-left: 5px;
}
@media (min-width: 1920px) {
	.title {
		font-size: 20px;
	}
}

.wrapper_section {
	padding: 20px 0px;
}
.wrapper_section img {
	width: 20px;
	height: auto;
	margin-bottom: 10px;
}
@media (min-width: 1920px) {
	.wrapper_section {
		padding-left: 27px 0px;
	}
	.wrapper_section img {
		width: 24px;
		height: auto;
	}
}
.icon_section {
	position: absolute;
	right: 32px;
	top: 18px;
	display: flex;
	z-index: 15;
	flex-direction: column;
	height: 65px;
	justify-content: space-between;
	align-items: center;
}
.icon_section.edit_mode {
	justify-content: start;
}
.icon_section img {
	cursor: pointer !important;
	width: 20px;
	height: auto;
}
@media (min-width: 1920px) {
	.icon_section img {
		width: 24px;
		height: auto;
	}
}
