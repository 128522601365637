.color-primary_dark {
  color: #002b64;
}

.w-25 {
  width: 25%;
}

.w-10 {
  width: 10%;
}

.mw-10 {
  min-width: 10%;
}

.mxw-10 {
  max-width: 10px;
}

.h-10 {
  height: 10%;
}

.mh-10 {
  min-height: 10%;
}

.mxh-10 {
  max-height: 10px;
}

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.m-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 10px;
}

.mr-1 {
  margin-right: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.ml-1 {
  margin-left: 10px;
}

.mv-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mh-1 {
  margin-right: 10px;
  margin-left: 10px;
}

.p-1 {
  padding: 10px;
}

.pt-1 {
  padding-top: 10px;
}

.pr-1 {
  padding-right: 10px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pl-1 {
  padding-left: 10px;
}

.pv-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ph-1 {
  padding-right: 10px;
  padding-left: 10px;
}

.pt-nav {
  padding-top: 140px;
}

.w-20 {
  width: 20%;
}

.mw-20 {
  min-width: 20%;
}

.mxw-20 {
  max-width: 20px;
}

.h-20 {
  height: 20%;
}

.mh-20 {
  min-height: 20%;
}

.mxh-20 {
  max-height: 20px;
}

.m-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mt-2 {
  margin-top: 20px;
}

.mr-2 {
  margin-right: 20px;
}

.mb-2 {
  margin-bottom: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.mv-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mh-2 {
  margin-right: 20px;
  margin-left: 20px;
}

.p-2 {
  padding: 20px;
}

.pt-2 {
  padding-top: 20px;
}

.pr-2 {
  padding-right: 20px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pl-2 {
  padding-left: 20px;
}

.pv-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ph-2 {
  padding-right: 20px;
  padding-left: 20px;
}

.pt-nav {
  padding-top: 140px;
}

.w-30 {
  width: 30%;
}

.mw-30 {
  min-width: 30%;
}

.mxw-30 {
  max-width: 30px;
}

.h-30 {
  height: 30%;
}

.mh-30 {
  min-height: 30%;
}

.mxh-30 {
  max-height: 30px;
}

.m-3 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mt-3 {
  margin-top: 30px;
}

.mr-3 {
  margin-right: 30px;
}

.mb-3 {
  margin-bottom: 30px;
}

.ml-3 {
  margin-left: 30px;
}

.mv-3 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mh-3 {
  margin-right: 30px;
  margin-left: 30px;
}

.p-3 {
  padding: 30px;
}

.pt-3 {
  padding-top: 30px;
}

.pr-3 {
  padding-right: 30px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pl-3 {
  padding-left: 30px;
}

.pv-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ph-3 {
  padding-right: 30px;
  padding-left: 30px;
}

.pt-nav {
  padding-top: 140px;
}

.w-40 {
  width: 40%;
}

.mw-40 {
  min-width: 40%;
}

.mxw-40 {
  max-width: 40px;
}

.h-40 {
  height: 40%;
}

.mh-40 {
  min-height: 40%;
}

.mxh-40 {
  max-height: 40px;
}

.m-4 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mt-4 {
  margin-top: 40px;
}

.mr-4 {
  margin-right: 40px;
}

.mb-4 {
  margin-bottom: 40px;
}

.ml-4 {
  margin-left: 40px;
}

.mv-4 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mh-4 {
  margin-right: 40px;
  margin-left: 40px;
}

.p-4 {
  padding: 40px;
}

.pt-4 {
  padding-top: 40px;
}

.pr-4 {
  padding-right: 40px;
}

.pb-4 {
  padding-bottom: 40px;
}

.pl-4 {
  padding-left: 40px;
}

.pv-4 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ph-4 {
  padding-right: 40px;
  padding-left: 40px;
}

.pt-nav {
  padding-top: 140px;
}

.w-50 {
  width: 50%;
}

.mw-50 {
  min-width: 50%;
}

.mxw-50 {
  max-width: 50px;
}

.h-50 {
  height: 50%;
}

.mh-50 {
  min-height: 50%;
}

.mxh-50 {
  max-height: 50px;
}

.m-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mt-5 {
  margin-top: 50px;
}

.mr-5 {
  margin-right: 50px;
}

.mb-5 {
  margin-bottom: 50px;
}

.ml-5 {
  margin-left: 50px;
}

.mv-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mh-5 {
  margin-right: 50px;
  margin-left: 50px;
}

.p-5 {
  padding: 50px;
}

.pt-5 {
  padding-top: 50px;
}

.pr-5 {
  padding-right: 50px;
}

.pb-5 {
  padding-bottom: 50px;
}

.pl-5 {
  padding-left: 50px;
}

.pv-5 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ph-5 {
  padding-right: 50px;
  padding-left: 50px;
}

.pt-nav {
  padding-top: 140px;
}

.w-60 {
  width: 60%;
}

.mw-60 {
  min-width: 60%;
}

.mxw-60 {
  max-width: 60px;
}

.h-60 {
  height: 60%;
}

.mh-60 {
  min-height: 60%;
}

.mxh-60 {
  max-height: 60px;
}

.m-6 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mt-6 {
  margin-top: 60px;
}

.mr-6 {
  margin-right: 60px;
}

.mb-6 {
  margin-bottom: 60px;
}

.ml-6 {
  margin-left: 60px;
}

.mv-6 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mh-6 {
  margin-right: 60px;
  margin-left: 60px;
}

.p-6 {
  padding: 60px;
}

.pt-6 {
  padding-top: 60px;
}

.pr-6 {
  padding-right: 60px;
}

.pb-6 {
  padding-bottom: 60px;
}

.pl-6 {
  padding-left: 60px;
}

.pv-6 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ph-6 {
  padding-right: 60px;
  padding-left: 60px;
}

.pt-nav {
  padding-top: 140px;
}

.w-70 {
  width: 70%;
}

.mw-70 {
  min-width: 70%;
}

.mxw-70 {
  max-width: 70px;
}

.h-70 {
  height: 70%;
}

.mh-70 {
  min-height: 70%;
}

.mxh-70 {
  max-height: 70px;
}

.m-7 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mt-7 {
  margin-top: 70px;
}

.mr-7 {
  margin-right: 70px;
}

.mb-7 {
  margin-bottom: 70px;
}

.ml-7 {
  margin-left: 70px;
}

.mv-7 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mh-7 {
  margin-right: 70px;
  margin-left: 70px;
}

.p-7 {
  padding: 70px;
}

.pt-7 {
  padding-top: 70px;
}

.pr-7 {
  padding-right: 70px;
}

.pb-7 {
  padding-bottom: 70px;
}

.pl-7 {
  padding-left: 70px;
}

.pv-7 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ph-7 {
  padding-right: 70px;
  padding-left: 70px;
}

.pt-nav {
  padding-top: 140px;
}

.w-80 {
  width: 80%;
}

.mw-80 {
  min-width: 80%;
}

.mxw-80 {
  max-width: 80px;
}

.h-80 {
  height: 80%;
}

.mh-80 {
  min-height: 80%;
}

.mxh-80 {
  max-height: 80px;
}

.m-8 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mt-8 {
  margin-top: 80px;
}

.mr-8 {
  margin-right: 80px;
}

.mb-8 {
  margin-bottom: 80px;
}

.ml-8 {
  margin-left: 80px;
}

.mv-8 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mh-8 {
  margin-right: 80px;
  margin-left: 80px;
}

.p-8 {
  padding: 80px;
}

.pt-8 {
  padding-top: 80px;
}

.pr-8 {
  padding-right: 80px;
}

.pb-8 {
  padding-bottom: 80px;
}

.pl-8 {
  padding-left: 80px;
}

.pv-8 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ph-8 {
  padding-right: 80px;
  padding-left: 80px;
}

.pt-nav {
  padding-top: 140px;
}

.w-90 {
  width: 90%;
}

.mw-90 {
  min-width: 90%;
}

.mxw-90 {
  max-width: 90px;
}

.h-90 {
  height: 90%;
}

.mh-90 {
  min-height: 90%;
}

.mxh-90 {
  max-height: 90px;
}

.m-9 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mt-9 {
  margin-top: 90px;
}

.mr-9 {
  margin-right: 90px;
}

.mb-9 {
  margin-bottom: 90px;
}

.ml-9 {
  margin-left: 90px;
}

.mv-9 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mh-9 {
  margin-right: 90px;
  margin-left: 90px;
}

.p-9 {
  padding: 90px;
}

.pt-9 {
  padding-top: 90px;
}

.pr-9 {
  padding-right: 90px;
}

.pb-9 {
  padding-bottom: 90px;
}

.pl-9 {
  padding-left: 90px;
}

.pv-9 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.ph-9 {
  padding-right: 90px;
  padding-left: 90px;
}

.pt-nav {
  padding-top: 140px;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  min-width: 100%;
}

.mxw-100 {
  max-width: 100px;
}

.h-100 {
  height: 100%;
}

.mh-100 {
  min-height: 100%;
}

.mxh-100 {
  max-height: 100px;
}

.m-10 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mt-10 {
  margin-top: 100px;
}

.mr-10 {
  margin-right: 100px;
}

.mb-10 {
  margin-bottom: 100px;
}

.ml-10 {
  margin-left: 100px;
}

.mv-10 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mh-10 {
  margin-right: 100px;
  margin-left: 100px;
}

.p-10 {
  padding: 100px;
}

.pt-10 {
  padding-top: 100px;
}

.pr-10 {
  padding-right: 100px;
}

.pb-10 {
  padding-bottom: 100px;
}

.pl-10 {
  padding-left: 100px;
}

.pv-10 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ph-10 {
  padding-right: 100px;
  padding-left: 100px;
}

.pt-nav {
  padding-top: 140px;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.ov-hidden {
  overflow: hidden;
}

.ov-y-auto {
  overflow-y: auto;
}

.contained {
  width: 90%;
  max-width: 1600px;
  margin: auto;
}

.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

.tt-up {
  text-transform: uppercase;
}

.bold {
  font-family: "Avenir Next Bold", sans-serif;
}

.italic {
  font-style: italic;
}

.box-shadow-btm-light {
  box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.1);
}

.box-shadow-light {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.box-shadow-wide {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.box-shadow-none {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.flex {
  display: flex;
}
.flex.col {
  flex-direction: column;
}
.flex.row {
  flex-direction: row;
}
.flex.jc-fs {
  justify-content: flex-start;
}
.flex.ai-fs {
  align-items: flex-start;
}
.flex.jc-fe {
  justify-content: flex-end;
}
.flex.ai-fe {
  align-items: flex-end;
}
.flex.jc-sb {
  justify-content: space-between;
}
.flex.ai-sb {
  align-items: space-between;
}
.flex.jc-se {
  justify-content: space-evenly;
}
.flex.ai-se {
  align-items: space-evenly;
}
.flex.jc-c {
  justify-content: center;
}
.flex.ai-c {
  align-items: center;
}
.flex.wrap {
  flex-wrap: wrap !important;
}

.ov-visible {
  overflow: visible;
}

.ov-hidden {
  overflow: hidden;
}

.ov-auto {
  overflow: auto;
}

.border-normal {
  border-radius: 0;
}

.border-rounded {
  border-radius: 12px;
}

.border-pill {
  border-radius: 100px;
}

.abs {
  position: absolute;
}
.abs.top {
  top: 0;
}
.abs.right {
  right: 0;
}
.abs.btm {
  bottom: 0;
}
.abs.left {
  left: 0;
}

.flt-left {
  float: left;
}

.flt-right {
  float: right;
}

.rel {
  position: relative;
}

.pos-static {
  position: static !important;
}

.grid-col-1 {
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-1 {
    grid-template-columns: 1fr;
  }
}

.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-2 {
    grid-template-columns: 1fr;
  }
}

.grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-3 {
    grid-template-columns: 1fr;
  }
}

.grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-4 {
    grid-template-columns: 1fr;
  }
}

.grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-5 {
    grid-template-columns: 1fr;
  }
}

.grid-col-6 {
  grid-template-columns: repeat(6, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-6 {
    grid-template-columns: 1fr;
  }
}

.grid-col-7 {
  grid-template-columns: repeat(7, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-7 {
    grid-template-columns: 1fr;
  }
}

.grid-col-8 {
  grid-template-columns: repeat(8, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-8 {
    grid-template-columns: 1fr;
  }
}

.grid-col-9 {
  grid-template-columns: repeat(9, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-9 {
    grid-template-columns: 1fr;
  }
}

.grid-col-10 {
  grid-template-columns: repeat(10, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-10 {
    grid-template-columns: 1fr;
  }
}

.grid-col-11 {
  grid-template-columns: repeat(11, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-11 {
    grid-template-columns: 1fr;
  }
}

.grid-col-12 {
  grid-template-columns: repeat(12, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-12 {
    grid-template-columns: 1fr;
  }
}

.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 30px;
}

.gap-4 {
  gap: 40px;
}

.gap-5 {
  gap: 50px;
}

.gap-6 {
  gap: 60px;
}

.gap-7 {
  gap: 70px;
}

.gap-8 {
  gap: 80px;
}

.grid-2-1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-2-1 {
    grid-template-columns: 1fr;
  }
}

.grid-1-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media screen and (max-width: 768px) {
  .grid-1-2 {
    grid-template-columns: 1fr;
  }
}

.grid-a-a {
  display: grid;
  grid-template-columns: auto auto;
}
@media screen and (max-width: 768px) {
  .grid-a-a {
    grid-template-columns: 1fr;
  }
}

.grid-1-4 {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
@media screen and (max-width: 768px) {
  .grid-1-4 {
    grid-template-columns: 1fr;
  }
}

.grid-4-1 {
  display: grid;
  grid-template-columns: 4fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-4-1 {
    grid-template-columns: 1fr;
  }
}

.grid-1-3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
@media screen and (max-width: 768px) {
  .grid-1-3 {
    grid-template-columns: 1fr;
  }
}

.grid-3-1 {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-3-1 {
    grid-template-columns: 1fr;
  }
}

.grid-3-2 {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
@media screen and (max-width: 768px) {
  .grid-3-2 {
    grid-template-columns: 1fr;
  }
}

.grid-3-1-1 {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-3-1-1 {
    grid-template-columns: 1fr;
  }
}

.contained {
  width: 90%;
  max-width: 1600px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .contained {
    width: 100%;
  }
}

.grid-col-1 {
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-1 {
    grid-template-columns: 1fr;
  }
}

.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-2 {
    grid-template-columns: 1fr;
  }
}

.grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-3 {
    grid-template-columns: 1fr;
  }
}

.grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-4 {
    grid-template-columns: 1fr;
  }
}

.grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-5 {
    grid-template-columns: 1fr;
  }
}

.grid-col-6 {
  grid-template-columns: repeat(6, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-6 {
    grid-template-columns: 1fr;
  }
}

.grid-col-7 {
  grid-template-columns: repeat(7, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-7 {
    grid-template-columns: 1fr;
  }
}

.grid-col-8 {
  grid-template-columns: repeat(8, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-8 {
    grid-template-columns: 1fr;
  }
}

.grid-col-9 {
  grid-template-columns: repeat(9, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-9 {
    grid-template-columns: 1fr;
  }
}

.grid-col-10 {
  grid-template-columns: repeat(10, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-10 {
    grid-template-columns: 1fr;
  }
}

.grid-col-11 {
  grid-template-columns: repeat(11, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-11 {
    grid-template-columns: 1fr;
  }
}

.grid-col-12 {
  grid-template-columns: repeat(12, 1fr);
  display: grid;
}
@media screen and (max-width: 768px) {
  .grid-col-12 {
    grid-template-columns: 1fr;
  }
}

.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 30px;
}

.gap-4 {
  gap: 40px;
}

.gap-5 {
  gap: 50px;
}

.gap-6 {
  gap: 60px;
}

.gap-7 {
  gap: 70px;
}

.gap-8 {
  gap: 80px;
}

.grid-2-1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-2-1 {
    grid-template-columns: 1fr;
  }
}

.grid-1-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media screen and (max-width: 768px) {
  .grid-1-2 {
    grid-template-columns: 1fr;
  }
}

.grid-a-a {
  display: grid;
  grid-template-columns: auto auto;
}
@media screen and (max-width: 768px) {
  .grid-a-a {
    grid-template-columns: 1fr;
  }
}

.grid-1-4 {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
@media screen and (max-width: 768px) {
  .grid-1-4 {
    grid-template-columns: 1fr;
  }
}

.grid-4-1 {
  display: grid;
  grid-template-columns: 4fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-4-1 {
    grid-template-columns: 1fr;
  }
}

.grid-1-3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
@media screen and (max-width: 768px) {
  .grid-1-3 {
    grid-template-columns: 1fr;
  }
}

.grid-3-1 {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-3-1 {
    grid-template-columns: 1fr;
  }
}

.grid-3-2 {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
@media screen and (max-width: 768px) {
  .grid-3-2 {
    grid-template-columns: 1fr;
  }
}

.grid-3-1-1 {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-3-1-1 {
    grid-template-columns: 1fr;
  }
}

.contained {
  width: 90%;
  max-width: 1600px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .contained {
    width: 100%;
  }
}

.pill {
  padding: 4px 10px;
  background-color: var(--colour-blue);
  color: var(--colour-white);
  border-radius: 20px;
}

.box-border {
  padding: 4px 10px;
  border: 1px solid var(--colour-gray);
}

.emailBtn {
  padding: 3px;
  margin: 3px;
  height: 40px;
  width: 40px;
  background-color: var(--colour-blue);
  border-radius: 50%;
}
