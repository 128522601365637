.innerSummary {
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
}
.innerSummary div{
 color: #EC7346;
 font-weight: bold;
 font-family: 'Avenir Next Bold';
 
}
.innerSummary span {
  font-weight: normal;
  display: block;
  padding-top: 3px;
}
.summaryLogo {
  height: 40px; 
  padding-bottom: 7px;
}

.summaryLogo img {
  height: 40px;
  width: auto; 
}
.legalIcon {
  height: 35px !important;
}
.wrapperChart {
  position: relative;
}
.chart_section {
  position: absolute;
 /*  width: 400px; */
  height: 100%;
  top: -210px;
  right: -210px;
  z-index: 10;
}
.mainSection {
  margin-top: 25px;
}
.wrapper_switch {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 26px;
  padding-bottom: 26px;
}
.swith_type_contact {
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 265px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E3E3E3;
  border-radius: 40px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.swith_type_contact div {
  padding: 20px 0px;
  width: 80px;
  height: 80px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}
.swith_type_contact div img {
  width: 33px;
  height: auto;
}

.switch_active {
  background-color: #002B64;
  border-radius: 50%;
}
.switch_active img {
  width: 33px;
  height: auto;
}
.mark_plus {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  width: 10px;
  height: 10px;
  background-color: #ffffff; 
  color: #002B64;
  margin-right: 5px;
}
.marks_row {
  padding-top: 17px;
  padding-bottom: 19px;
}

.call_icon_section img {
  margin: 0px 5px;
}
.call_icon_section_arrow img {
  margin: 0px 5px;
  height: 20px;
}

.notes_icon img{
  margin-left: 10px;
  cursor: pointer;
}

@media (min-width:1920px){
  .innerSummary {
    padding-top: 15px;
    font-size: 20px;
  }

   .summaryLogo {
    height: 51px; 
    padding-bottom: 11px;
  }
  .summaryLogo img {
    height: auto;
    width: auto; 
  }
  .innerSummary span {
    padding-top: 5px;
  }
  .legalIcon {
    height: 43px !important;
  }
  .mainSection {
    margin-top: 33px;
  }
  .chart_section {
    top: -210px;
    right: -223px;
  }
   
}
