.datePickerContainer {
    width: 100%;
}

.datePicker {
    border-radius: 24px;
    outline: none;
    box-sizing: border-box;
    border: 1px solid var(--colour-blue);
    height: 47px;
    width: 100%;
    font-size: 15px;
    font-family: "Avenir Next";
    color: #002b64;
    padding-left: 15px;
    background-color: var(--colour-white);
    padding-top: 6px;
}

.datePickerTitle {
    font-size: 15px;
    font-family: "Avenir Next Bold";
    color: #002b64;
    text-transform: uppercase;
    padding-left: 20px;
    padding-bottom: 5px;
}