.reminderContainer {
	min-width: 300px;
	height: 55px;
	background-color: #002b64;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 50px;
	position: relative;
	padding: 15px;
}

.reminderTitle{ 
	margin: 0px;
	font-weight: "bold"; 
	font-family: 'Avenir Next Bold';
}

.reminderInfo{
	padding-left: 10px;
}

.reminderClose {
	display: none !important;
	position: absolute !important;
	top: 0;
	left: 100%;
	background: white !important;
	border: 2px solid #002b64 !important;
	width: 25px;
	height: 25px;
	padding: 0;
	transform: translate(-25px, -10px);

}

.reminderContainer:hover .reminderClose {
	display: inline-flex !important;
}
