@font-face {
    font-family: "Avenir Next Bold";
    font-weight: bold;
    src: url(./assets/fonts/AvenirNextLTPro-Bold.otf) format("opentype");
}
@font-face {
    font-family: "Avenir Next Demi Bold";
    src: url(./assets/fonts/Avenir\ Next-SemiBold.otf) format("opentype");
}
@font-face {
    font-family: "Avenir Next It";
    src: url(./assets/fonts/AvenirNextLTPro-It.otf) format("opentype");
}
@font-face {
    font-family: "Avenir Next Medium";
    src: url(./assets/fonts/Avenir\ Next-Medium.otf) format("opentype");
}
@font-face {
    font-family: "Avenir Next";
    src: url(./assets/fonts/AvenirNextLTPro-Regular.otf) format("opentype");
}

:root {
    --colour-blue: #002b64;
    --colour-gray: #f0f0f0;
    --colour-orange: #ec7346;
    --colour-white: #ffffff;
    --colour-green: #56e166;
    --colour-lightGray: #a8a8a8;
    --colour-lightBlue: #184278;
    --colour-red: #f52c2c;
    --colour-purple: #931a8b;
    --colour-darkGreen: #00a520;
}

.app {
    font-family: Avenir Next, Arial, Helvetica, sans-serif;
    min-height: 100%;
    height: 100%;
}

.MuiPopover-paper {
    border: 1px solid #01579b;
    border-radius: 15px !important;

    /* transform: scale(0.7) !important; */
}
.MuiPickersSlideTransition-transitionContainer > * {
    text-align: left !important;
    color: #01579b;
    font-weight: bold !important;
    font-size: 18px !important;
}
/* .MuickersBasePicker-pickerView {
  display: flex;
  min-width: 250px !important;
  min-height: 250px !important;
} */
.MuiTypography-body2 {
    font-size: 14px;
}

.mark_plus {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    width: 10px;
    font-size: 10px;
    height: 10px;
    background-color: #ffffff;
    color: #002b64;
    margin-right: 5px;
    margin-bottom: 3px;
    /* padding-bottom: 3px; */
    box-sizing: border-box;
    margin-left: 0px !important;
}
.inner_mark {
    font-weight: bold;
    font-size: 10px;
    color: #002b64;
    margin-left: 0px !important;
}
.baseText {
    text-transform: none;
}
.upload {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0;
}

@media (min-width: 1920px) {
    .mark_plus {
        width: 14px;
        height: 14px;
        font-size: 14px;
        padding-bottom: 2px;
    }
    .inner_mark {
        font-size: 14px;
    }
}

.flex {
    display: flex;
}
.flex.row {
    flex-direction: row;
}
.flex.col {
    flex-direction: column;
}
.flex.ai-c {
    align-items: center;
}
.flex.jc-c {
    justify-content: center;
}

.clickable {
    cursor: pointer;
}

.select-input-e4 {
    width: 100%;
    height: 50px;
    background-color: var(--colour-gray);
    border: 0;
    border-radius: 100px;
    margin-right: 2em;
    font-family: "Avenir Next", sans-serif;
    font-size: 16px;
    color: var(--colour-blue);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.select-input-e4:focus {
    outline: none;
}

.select-input {
    width: 100%;
    height: 50px;
    background-color: var(--colour-gray);
    border: 0;
    border-radius: 100px;
    padding: 16px;
    margin-right: 2em;
    font-family: "Avenir Next", sans-serif;
    font-size: 16px;
    color: var(--colour-blue);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.select-input:focus {
    outline: none;
}

.table-search > input {
    outline: 1px blue;
    border-radius: 20px;
}

.table-search > div {
    position: absolute;
    top: 0;
    right: 0;
}

.colour-blue {
    color: var(--colour-blue);
}

.fc-timegrid-event-harness {
    width: 50% !important;
}

.fc-day-today {
    background: rgba(0, 43, 100, 0.4) !important;
}

.fc-col-header > thead {
    display: none !important;
}

.fc-scrollgrid-sync-table > tbody > tr {
    display: none !important;
}

.fc-timegrid-divider {
    display: none !important;
}

.MuiButton-label {
    margin-left: 0 !important;
}
