.addEventContainer {
	height: 550px;
	padding: 20px;
}

.addEventHeader {
	text-align: center;
	color: var(--colour-blue);
}

.inputs {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	margin-bottom: 30px;
}

.selectDate {
	border: 1px solid var(--colour-blue);
}

.addEventSubmit {
	width: 100%;
	text-align: center;
	padding: 20px 0;
}