.assignDebtorsContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-radius: 15px;
	background-color: white;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.closeIcon {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 50px;
	height: 50px;
}
