.title {
  text-transform: uppercase;
  font-family: 'Avenir Next Bold';
  font-size: 15px;
}
.images_wrapper {
  display: flex;
  flex-direction: column;
}

.client_manager {
  flex-direction: row;
}
.client_manager img{
  margin: 0px 8px;
  width: 24px;
  height: auto;
}
.images_wrapper img{
  height: 24px;
  width: auto;
  margin: 5px;
}

.title_inner {
  color: #EC7346;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  font-family: 'Avenir Next Bold';
  padding-bottom: 13px;
  display: flex;
}

.titl_inner span {
  margin-left: 20px; 
  margin-top: -5px;
}

.text {
  color: #002B64; 
  font-size: 15px;
  font-family: 'Avenir Next Medium';
  display: flex;
  align-items: center;
}
.text span {
  margin-left: 5px; 
  line-height: 20px;
}
.wrapper_section {
  padding: 20px 0px 40px 0px; 
}
.info_client {
  padding-left: 40px;
}
.call_icon_section img {
  margin: 0px 5px;
}
.table_section {
  margin-top: 60px;
}
.add_photo {
  width: 171px;
  height: 171px;
  border-radius: 50%;
  background-color: #F0F0F0;
  font-family: 'Avenir Next Bold';
  font-size: 15px;
  color: #002B64; 
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.wrapper_input {
  position: relative;
}
.input_title {
  font-size: 12px;
  font-family: 'Avenir Next Bold';
  color: #002B64;
  text-transform: uppercase;
  padding-left: 20px;
  padding-bottom: 5px;
}

.wrapper_input input {
  background: #F0F0F0;
  border-radius: 24px;
  outline: none;
  box-sizing: border-box;
  border: none;
  height: 47px;
  width: 100%;
  font-size: 15px;
  font-family: 'Avenir Next';
  color: #002B64;
  padding-left: 15px;
}
 .wrapper_input label {
  color: red;
  text-align: left;
  padding-left: 15px;
  margin-top: 5px;
  display: inline-block;
}
.select_field {
  position: absolute; 
  z-index: 10;
  bottom: 30px;
  right: 30px;

}
.wrapper_logo {

}

.wrapper_logo img{
 width: 192px;
 height: 183px;
}

@media (min-width:1920px){
  .title {
    font-size: 20px;
  }
  .title_inner {
    font-size: 20px;
    padding-bottom: 17px;
  }
  .text {
    font-size: 20px;
  }
  .add_photo {
    font-size: 20px;
  }
  .wrapper_input div {
    font-size: 15px;
  }
  .wrapper_logo img{
    width: 242px;
    height: 233px;
   }
}