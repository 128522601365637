.tagInputContainer {
    min-height: 45px;
    border: 1px solid var(--colour-blue);
    border-radius: 25px;
}

.tagInputContainer > input {
    background-color: transparent;
    min-width: 100px;
    border: 0;
    outline: none;
}