.modal_content {
	padding: 34px 25px;
	box-sizing: border-box;
}
.modal_content span.title {
	font-size: 30px;
	color: var(--colour-blue);
	text-align: left;
	font-family: "Avenir Next Bold";
}
@media (min-width: 1920px) {
	.modal_content span.title {
		font-size: 40px;
	}
}
