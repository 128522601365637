.title_table {
  color: #002B64;
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'Avenir Next Bold';
  margin-bottom: 21px;
}
.call_icon_section img {
  margin: 0px 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.device_box {
  width: 337px;
  height: 609px;
  padding: 25px 30px;
  box-sizing: border-box;
  border: 1px solid #C7C7C7;
  border-radius: 20px;
  background-color: #002B64; 
  margin-top: 40px;
}
.search_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
}
.wrap_circle {
  display: flex;
  justify-content: center;
}
.circle {
  margin-top: 20px;
  width: 73px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color:#002B64;
  background-color: #FFFFFF; 
  font-size: 47px;
  cursor: pointer;
}
.call_icons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btn_call {
  margin-top: 20px;
  cursor: pointer;
}
.green_fon {
  background-color: #00D171; 
}
.red_fon {
  background-color: #C10101; 
}

@media (min-width:1920px){
  .title_table {
    font-size: 20px;
    margin-bottom: 28px;
  }
  .device_box {
    width: 337px;
    height: 609px;
    box-sizing: border-box;
    border: 1px solid #C7C7C7;
    border-radius: 20px;
    background-color: #002B64; 
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  
    align-items: center;
  }
  

}