:root {
	--persistent-padding: min(10%, 20px);
}

.persistentContainer {
	position: absolute;
	display: grid;
	place-items: center;
}

.horizontalRight {
	left: calc(100% - var(--persistent-padding));
	transform: translateX(-100%);
}

.horizontalLeft {
	left: var(--persistent-padding);
}

.verticalTop {
	top: var(--persistent-padding);
}

.verticalBottom {
	top: calc(100% - var(--persistent-padding));
	transform: translateY(-100%);
}

.verticalBottom.horizontalRight {
	transform: translate(-100%, -100%);
}
