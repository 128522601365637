.container {
	position: relative;
	padding: 50px;
	height: 100%;
	min-width: 380px;
}

.close {
	position: absolute !important;
	top: 5px;
	right: 5px;
}
