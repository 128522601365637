.mark_plus {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  width: 10px;
  font-size: 10px;
  height: 10px;
  background-color: #ffffff; 
  color: #002B64;
  margin-right: 5px;
  margin-bottom: 3px;
  /* padding-bottom: 3px; */
  box-sizing:border-box;
  margin-left: 0px !important;

}
.inner_mark {
  font-weight: bold;
  font-size: 10px;
  color: #002B64;
  margin-left: 0px !important;
}
.title_btn {
  margin-left: 0 !important;
}
.text_month {
  color: #C3C3C3;
  font-size: 12px;
  cursor: pointer;
  padding: 0 15px;
  text-transform: uppercase;
  font-family: 'Avenir Next Bold';
}
.section_month {
  height: 50px;
  display: flex;
  align-items:center;
 /*  padding-right: 20px; */
}
.active_month {
  color: #002B64;
  /* padding: 0 15px; */
  height: 100%;
  border-left: 1px solid #E3E3E3;
  border-right: 1px solid #E3E3E3;
  justify-content: center;
  display: flex;
  align-items:center;
}
.circle_section {
  position: relative; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #FFFFFF;
  font-size: 39px;
  background-color: #E3E3E3;
  display: flex;
  align-items: center;
  padding-top: 3px;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
}
.text_day {
  margin-top: 10px;
  font-size: 12px;
  color:#C3C3C3;
  text-transform: uppercase;
}
.today_text {
  position: absolute; 
  color:#002B64;
  font-size: 12px;
  text-transform: uppercase;
  top: -20px;
}
.active_circle {
  background-color: #002B64; 
}
.active_txt {
  color: #002B64; 
}

.messages_main {
  width: 100%; 
  height: 100%;
  display: flex;
  position: relative;
}
.messages_scale {
  height: 100%;
  width: 65px; 
 
}
.box_scale {
  position: relative;
  height: 90px;
  width: 100%;
  text-align: left;
}

.title_scale {
  color: #C3C3C3; 
  font-size: 15px;
  margin-right: 20px;
}

.message_content {
  width: 100%; 
  height: 720px;
  margin-top: 5px;
  position: relative; 
}
.innerMessage_section {
  position: absolute;
  box-sizing: border-box;
  background-color: #002B64; 
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  color: #FFFFFF; 
  padding: 10px 12px;
  max-width: 400px;
  width: max-content;
  font-size: 15px;
  z-index: 10;
}
.borders{
  width: 80%;
  height: 180px;
  margin-left: -5px;
  border-top: 2px solid #E3E3E3;
}
.divider_borders {
  width: 100%;
  height: 90px;
  margin-left: 5px;
  border-bottom: 1px solid #E3E3E3;
}

.inner_title {
  font-family: 'Avenir Next Demi Bold'; 
  margin: 10px 0px;
  margin-right: 5px;
}
.inner_text {
  display: inline-block;
}
.time_text {
  font-family: 'Avenir Next Bold';
  font-size: 10px;
  color:#EC7346;
  margin-right: 5px;
  margin-bottom: 3px;
  display: block;
}
.box_summary {
  width: 278px;
  height: 360px;
  position: absolute;
  right: 0;
  margin-top: 127px;
  padding: 30px 0px 20px 0px;
  box-sizing: border-box;
  background-color: #002B64;
  box-shadow: -3px 3px 6px #0000004D;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 20;
}
.summary_inner :first-child {
  color: #EC7346; 
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'Avenir Next Bold';
  text-align: center;
  max-width: 150px;
}
.summary_inner :last-child {
  color: #FFFFFF; 
  text-transform: uppercase;
  font-size: 30px;
  font-family: 'Avenir Next Bold';
  text-align: center;
  margin-top: 10px;
}
.wrap_date {
  padding: 36px 20px 21px 0px;
  position: relative;
}
.mask_bottom {
  position: absolute;
  height: 80px;
  width: 100%;
  background: linear-gradient( rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
  opacity: 0.9;
  filter: blur(1.5rem)  contrast(175%);
  z-index: 10;
  bottom: 0;
  left: 0;
}
.mask_right {
  position: absolute;
  height: 100%;
  width: 100px;
  background: linear-gradient(to right,  rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9));
  opacity: 0.8;
  filter: blur(1.5rem)  contrast(175%);
  z-index: 10;
  right: 0;
}
.mask_rigth_main {
  position: absolute;
  height: 105%;
  width: 400px;
  background: linear-gradient(to right,  rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1));
  opacity: 1;
  filter: blur(1.5rem)  contrast(175%);
  z-index: 10;
  right: 0;
  top: -5px;
}

@media (min-width:1920px){
  .mark_plus {
    width: 14px;
    height: 14px;
    font-size: 14px;
    padding-bottom: 2px;
  }
  .inner_mark {
    font-size: 14px;
  }
 .text_month { 
  font-size: 15px;
 }
 .section_month {
  height: 62px;
 }
 .circle {
  width: 78px;
  height: 78px;
  font-size: 47px;
 }
 .text_day {
   font-size: 15px;
 }
 .today_text {
   font-size: 15px;
 }

}

.calendarContainer {
  padding: 0px;
  height: fit-content;
}

.toolBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin: 0 12px;
  border-bottom: var(--colour-gray);
}

.toolBarDate {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calendar {
  padding: 30px;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 20px;
}

.days {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.carouselBtn {
  cursor: pointer;
}

.day {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  padding: 8px;
  margin: 8px;
  margin-bottom: 16px;
  height: 120px;
}

.day.active {
  background: var(--colour-white);
  color: var(--colour-blue);
}

.day > h2 {
  margin: 0;
  margin-bottom: 6px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 40px;
  background: var(--colour-gray);
  color: var(--colour-white);
} 

.day.active > h2 {
  background: var(--colour-blue);
}

.day > p {
  margin: 0;
  margin-bottom: 6px;
  color: var(--colour-blue);
}

.eventCard {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.eventCard > p {
  margin: 0;

}

.eventTime {
  color: #fff;
}

.eventTime > span {
  color: var(--colour-orange);
}

.calendarSummaryCard {
  position: absolute;
  top: 342.5px;
  right: 0;
  width: 200px;
  height: 400px;
  background-color: var(--colour-blue);
  z-index: 10;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.calendarSummaryCard > div {
  text-align: center;
}

.calendarSummaryCard h3 { color: var(--colour-orange); font-family: 'Avenir Next Demi Bold'; }
.calendarSummaryCard h2 { color: #fff; font-family: 'Avenir Next Bold'; }

.slider { overflow: visible; }

.searchResults {
  margin-top: 10px;
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid var(--colour-blue);
}

.result {
  padding: 10px 4px;
  cursor: pointer;
  background-color: transparent;
  color: var(--colour-blue);
  text-align: center;
  border-bottom: 1px solid var(--colour-blue);
}

.result > h4 {
  color: var(--colour-orange);
  margin: 4px 0;
}

.result > h3 {
  margin: 8px 0;
}

.result:hover {
  background-color: var(--colour-blue);
  color: #fff;
}

.result:last-of-type { border-bottom: 0; }

.search { position: relative; }
.search > input {
  width: 180px;
  border: 1px solid var(--colour-blue);
  border-radius: 20px;
  padding: 8px 4px;
}
.search > img {
  position: absolute;
  border-radius: 50%;
  background-color: var(--colour-blue);
  padding: 7px;
  right: 0px;
  cursor: pointer;
}