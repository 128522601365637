.top_right {
  text-align: right;
}
.icon {
  margin-right: 20px;
}
 
.top_left {
  text-align: left;
}
.top_left :first-child {
  margin-right: 5px;
}
.top_left img {
  width: 56px;
  height: 56px;
  cursor: pointer;
}
.call_icon_section_arrow img {
  margin: 0px 5px;
  height: 20px;
  cursor: pointer;
}
.icon_section img {
  width: 27px;
  height: 27px;
  cursor: pointer;
}
.box_switch {
  width: 190px;
  height: 123px;
  margin-top: 40px;
  background-color: #F0F0F0; 
  border-radius: 11px;
  font-size: 15px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.item_switch {
  text-align: left;
  color: #002B64; 
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.active_item {
  color: #FFFFFF ;
  background-color: #002B64;
  border-radius: 11px;
}
.text_switch {
  padding-left: 14px;
}

@media (min-width:1920px){

  .icon_section img {
    width: 32px;
    height: 32px;
  }

}
