.modalContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	padding: 20px 0;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 1201;
	overflow-y: auto;
}

.modalBodyFull {
	width: 90%;
	min-height: 90%;
	background-color: #fff;
	overflow: auto;
}

.modalBodyLrg {
	width: 60%;
	min-height: 60%;
	background-color: #fff;
	overflow: auto;
}

.modalBodySml {
	position: relative;
	width: fit-content;
	min-height: fit-content;
	background-color: #fff;
	overflow: auto;
}
