.wrapper_input {
  position: relative;
}

.wrapper_input input,
.wrapper_input select {
  border-radius: 24px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid var(--colour-blue);
  height: 47px;
  width: 100%;
  font-size: 15px;
  font-family: "Avenir Next";
  color: #002b64;
  padding-left: 15px;
  background-color: var(--colour-white);
  padding-top: 6px;
}

.wrapper_input textarea {
    background: #f0f0f0;
    border-radius: 24px;
    outline: none;
    box-sizing: border-box;
    border: 1px solid var(--colour-blue);
    height: 200px;
    width: 100%;
    font-size: 15px;
    font-family: "Avenir Next";
    color: #002b64;
    padding-left: 15px;
    background-color: var(--colour-white);
  }

.wrapper_input label {
  color: red;
  text-align: left;
  padding-left: 15px;
  margin-top: 5px;
  display: inline-block;
}

.input_title {
  font-size: 12px;
  font-family: "Avenir Next Bold";
  color: #002b64;
  text-transform: uppercase;
  padding-left: 20px;
  padding-bottom: 5px;
}

.wrapper_input p {
  color: red;
  text-align: center;
  padding-left: 15px;
  margin: 10px 0;
}

.select_field {
  position: absolute; 
  z-index: 10;
  bottom: 16px;
  right: 10px;
  pointer-events: none;
}

@media (min-width: 1920px) {
  .title {
    font-size: 20px;
  }
  .title_inner {
    font-size: 20px;
    padding-bottom: 17px;
  }
  .text {
    font-size: 20px;
  }
  .add_photo {
    font-size: 20px;
  }
  .wrapper_input div {
    font-size: 15px;
  }
  .wrapper_logo img {
    width: 242px;
    height: 233px;
  }
  .text_replace {
    font-size: 20px;
  }
}
