.voipContainer {
	width: 425px;
	height: 75px;
	background-color: #002b64;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 50px;
	position: relative;
	padding: 15px;
}

.voipActionsContainer {
	display: flex;
	gap: 10px;
	justify-content: space-evenly;
}

.voipActionsButton {
	padding: 0;
	width: 50px;
	height: 50px;
}

.voipActionBackground {
	background-color: white !important;
}

.voipClose {
	display: none !important;
	position: absolute !important;
	top: 0;
	left: 100%;
	background: white !important;
	border: 2px solid #002b64 !important;
	width: 25px;
	height: 25px;
	padding: 0;
	transform: translate(-25px, -10px);
}

.voipContainer:hover .voipClose {
	display: inline-flex !important;
}
